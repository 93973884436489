import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const HowItWork = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Как работает сервис',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>
            
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="split-item-content left-content-mobile reveal-from-left">
                <h3 className="mt-0 mb-12">
                  1. Регистрация
                  </h3>
                <p className="m-0 text-sm">
                  Зарегистрируйтесь на сервисе и войдите в личный кабинет
                </p>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="split-item-content left-content-mobile reveal-from-right">
                <h3 className="mt-0 mb-12">
                  2. Настройка
                  </h3>
                <p className="m-0 text-sm">
                  Следуйте подсказкам в кабинете или обратитесь в чат за помощью
                </p>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">

              <div className="split-item-content left-content-mobile reveal-from-left">
                <h3 className="mt-0 mb-12">
                  3. Получение отчета
                  </h3>
                <p className="m-0 text-sm">
                  По завершению мониторинга скачайте отчет
                </p>
              </div>

            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

HowItWork.propTypes = propTypes;
HowItWork.defaultProps = defaultProps;

export default HowItWork;
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'

import App from './App';
import * as serviceWorker from './serviceWorker';

import './App.css';
import './assets/scss/style.scss';

const history = createBrowserHistory();

const firebaseConfig = {
  apiKey: "AIzaSyDqFtThMWRbn_2QBFIR4I9Eh1yPRFVJd14",
  authDomain: "price-landing.firebaseapp.com",
  projectId: "price-landing",
  storageBucket: "price-landing.appspot.com",
  messagingSenderId: "256989696652",
  appId: "1:256989696652:web:f8cdaf4c51ac2f5d1921a9",
  measurementId: "G-8G9KLR603J"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Cost = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Стоимость',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-right text-align-center" data-reveal-delay="200">
                <div className="split-item-content center-content-mobile reveal-from-left">
                  <h3 className="mt-0 mb-12">
                    1000 ₽
                    </h3>
                  <p className="m-0">
                    1 день
                  </p>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom text-align-center">
                <div className="split-item-content center-content-mobile reveal-from-right">
                  <h3 className="mt-0 mb-12">
                    4000 ₽
                  </h3>
                  <p className="m-0">
                    7 дней
                  </p>
                </div>
              </div>

              <div className="tiles-item reveal-from-left text-align-center" data-reveal-delay="200">

                <div className="split-item-content center-content-mobile reveal-from-left">
                  <h3 className="mt-0 mb-12">
                    10000 ₽
                  </h3>
                  <p className="m-0">
                    30 дней
                  </p>
                </div>
              </div>

            </div>

            <span className='cost-text'>оплата возможна банковской картой, через Юмани или по счету (безнал)</span>

        </div>
      </div>
    </section>
  );
}

Cost.propTypes = propTypes;
Cost.defaultProps = defaultProps;

export default Cost;
import React from 'react'
import classNames from 'classnames'
import { SectionProps } from '../../utils/SectionProps'

const propTypes = {
  ...SectionProps.types,
}

const defaultProps = {
  ...SectionProps.defaults,
}

const ContactData = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'hero-inner section-inner hero-inner-contact',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content contact-wrap">

            <div className="contact-form">
              <h3 className='contact-header'>Отправить запрос</h3>
              <iframe title="contact-form" src="https://forms.yandex.ru/cloud/61e012fab0ffa46794804c7a/?iframe=1" frameBorder="0"
                      name="ya-form-61e012fab0ffa46794804c7a" width="100%"></iframe>
            </div>

            <div className="container-xs container-xs-contact">
              <h3 className='contact-header'>Контакты</h3>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                <span>tel: </span>
                <a
                  href="tel:+74992868908"
                  rel="noopener noreferrer"
                >
                  +7 (499) 286-89-08
                </a>
              </p>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                <span>email: </span>
                <a
                  href="mailto:sale@pricehelper.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sale@pricehelper.ru
                </a>
              </p>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                <span>онлайн-чат: </span>{' '}
                <a
                  href="#Verbox/openSupport"
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  открыть
                </a>
              </p>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

ContactData.propTypes = propTypes
ContactData.defaultProps = defaultProps

export default ContactData

import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner hero-inner-title',
    topDivider && 'has-top-divider',
    true && 'has-bottom-divider'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom h1-mobile-font-size" data-reveal-delay="200">
              <span className="text-color-secondary">Мониторинг цен</span>
              <span className="text-color-primary title-custom">интернет магазинов</span>
            </h1>
          </div>

          <div className="hero-figure reveal-from-bottom illustration-element-01 titles-custom" data-reveal-value="20px" data-reveal-delay="800">

            <div className='titles-custom-text'>
              <p>Сервис позволит:</p>
              <ul>
                <li>отслеживать цены конкурентов</li>
                <li>сформировать ценообразование</li>
                <li>выявить и устранить демпинг</li>
                <li>повысить рентабельность</li>
                <li>получить больше продаж</li>
                <li>увеличить представленность товаров</li>
              </ul>

            </div>

            <Image
              className="titles-custom-image"
              src={require('./../../assets/images/main-image2.png')}
              alt="Мониторинг цен интернет магазинов"
              width='auto'
              height='auto'
            />

          </div>

          <div className="container-xs">
            <div className="reveal-from-bottom titles-custom-btn" data-reveal-delay="600">
              <Button className="custom-btn" tag="a" color="primary" wideMobile href="https://my.pricehelper.ru/signup">
                Регистрация
              </Button>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
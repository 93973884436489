import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ListCheck from './../../assets/images/list-check.svg'
import HandThumbsUp from './../../assets/images/hand-thumbs-up.svg'
import Search from './../../assets/images/search.svg'
import FileEearmark from  './../../assets/images/file-earmark-spreadsheet.svg'
import Collection from './../../assets/images/collection.svg'
import Messenger from './../../assets/images/messenger.svg'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Возможности сервиса',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 custom-features-tiles-item-image">
                    <Image
                      src={Search}
                      alt="Features tile icon 01"
                      width={50}
                      height={50}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <p className="m-0 text-sm">
                    Мониторинг популярных маркетплейсов
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 custom-features-tiles-item-image">
                    <Image
                      src={HandThumbsUp}
                      alt="Features tile icon 02"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <p className="m-0 text-sm">
                    Простой и понятный интерфейс, доступен тестовый период
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 custom-features-tiles-item-image">
                    <Image
                      src={ListCheck}
                      alt="Features tile icon 03"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <p className="m-0 text-sm">
                    Максимум на всех тарифных планах, нет разделения по функционалу
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 custom-features-tiles-item-image">
                    <Image
                      src={FileEearmark}
                      alt="Features tile icon 04"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <p className="m-0 text-sm">
                    Функциональные отчеты в EXCEL
                  </p>
                  <p className="m-0 text-sm tiles-item-price-example">
                    <a href="https://pricehelper.ru/pricehelper.ru_demo.xlsx" target="_blank" rel="noreferrer">Скачать пример</a>
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 custom-features-tiles-item-image">
                    <Image
                      src={Collection}
                      alt="Features tile icon 05"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <p className="m-0 text-sm">
                    Проведем выгрузку товаров с характеристиками и отзывами для наполнения вашего сайта
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 custom-features-tiles-item-image">
                    <Image
                      src={Messenger}
                      alt="Features tile icon 06"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <p className="m-0 text-sm">
                    Ответим на вопросы в популярных мессенджерах, больше не нужно находиться на сайте
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
import React from 'react';
// import sections
import ContactData from '../components/sections/ContactData';
import Cta from '../components/sections/Cta';

const Contacts = () => {

  return (
    <>
        <ContactData className="illustration-section-01" />
        <Cta split />
    </>
  );
}

export default Contacts;